import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import * as api from '@dki/api-client';
import { inOutAnimation } from '../inoutanimation/in-out-animation';
import {
	Period,
	IPeriodData,
	PERIODS_ORDER,
	IRestaurantData,
	THRESHOLD_INDIVIDUAL,
	THRESHOLD_TOTAL,
	TimeStep,
	ValueType,
} from '@libs/dash/core/entity';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

@Component({
	selector: 'data-table',
	templateUrl: './data-table.component.html',
	styleUrls: ['./data-table.component.scss'],
	animations: [inOutAnimation],
})
export class DataTableComponent implements AfterViewInit {
	@ViewChild('secondLabel') secondLabel!: ElementRef<HTMLElement>;
	@ViewChild('cell') cell!: ElementRef<HTMLElement>;
	@Input() restaurants: api.Restaurant[] = [];
	@Input() data: { [key: string]: IRestaurantData } = {};

	expandedRows: { [key: string]: boolean } = {};
	periodsOrder = PERIODS_ORDER;
	ValueType = ValueType;
	TimeStep = TimeStep;
	translations$: Observable<any>;

	constructor(private translocoService: TranslocoService) {}

	ngOnInit() {
		this.translations$ = this.translocoService.selectTranslateObject('sosKioskTile');
	}

	ngAfterViewInit(): void {
		this._positionSecondLabel();
	}

	@HostListener('window:resize')
	onResize(): void {
		this._positionSecondLabel();
	}

	private _positionSecondLabel(): void {
		const referenceRect = this.cell.nativeElement.getBoundingClientRect();
		const targetStyle = this.secondLabel.nativeElement.style;
		targetStyle.position = 'absolute';
		targetStyle.marginLeft = '-6ch';
		targetStyle.left = `${referenceRect.left}px`;
		targetStyle.height = `${referenceRect.height}px`;
	}

	toggleRowExpansion(restaurantId: string): void {
		this.expandedRows[restaurantId] = !this.expandedRows[restaurantId];
	}

	isRowExpanded(restaurantId: string): boolean {
		return this.expandedRows[restaurantId] || false;
	}

	getRestaurantName(id: string): string {
		return this.restaurants.find((res) => res.id === id)?.name || id;
	}

	formatTimeRange(value: { start: number; end: number }): string {
		const formatTime = (time: number) => time.toString().padStart(2, '0');
		const template = this.translocoService.translate('sosKioskTile.timeRange');
		return template.replace('{start}', formatTime(value.start)).replace('{end}', formatTime(value.end));
	}

	isThresholdExceeded(value: number, type: ValueType): boolean {
		if (!value) {
			return false;
		}
		return type === ValueType.Total ? value > THRESHOLD_TOTAL : value > THRESHOLD_INDIVIDUAL;
	}

	getSortedPeriods(periods: { [key in Period]?: IPeriodData }): IPeriodData[] {
		return PERIODS_ORDER.map((period) => periods[period]).filter((period): period is IPeriodData => period !== undefined);
	}

	calculateTotalForField(field: keyof IRestaurantData['total']): number {
		if (!this.data) {
			return 0;
		}
		return Object.values(this.data).reduce((total, restaurant) => {
			const value = restaurant?.total[field];
			return typeof value === 'number' ? total + value : total;
		}, 0);
	}

	calculateAverageTimeForField(field: TimeStep): number {
		if (!this.data) {
			return 0;
		}
		const { totalWeightedTime, totalCount } = Object.values(this.data).reduce(
			(acc, restaurant) => {
				const { total } = restaurant;
				if (total && typeof total[field] === 'number' && typeof total.count === 'number') {
					acc.totalWeightedTime += total[field] * total.count;
					acc.totalCount += total.count;
				}
				return acc;
			},
			{ totalWeightedTime: 0, totalCount: 0 }
		);
		return totalCount > 0 ? totalWeightedTime / totalCount : 0;
	}

	getClassForValue(value: number, type: ValueType): string {
		if (!value) {
			return 'white';
		}

		return this.isThresholdExceeded(value, type) ? 'red' : 'green';
	}
}
