import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedMaterialModule } from '@libs/dash/shared';
import { FormsModule } from '@angular/forms';
import { SecondsToMins } from '../dtt-export/pipes/seconds-to-mins.pipe';
import { DatePickerComponent } from './dumb-components/date-picker/date-picker.component';
import { DaysSelectorComponent } from './dumb-components/days-selector/days-selector.component';
import { ExportButtonComponent } from './dumb-components/export-button/export-button.component';
import { IndicatorComponent } from './dumb-components/indicator-card/indicator.component';
import { RestaurantPickerComponent } from './dumb-components/restaurant-picker/restaurant-picker.component';
import { SalesChannelSelectorComponent } from './dumb-components/sales-channel-selector/sales-channel-selector.component';
import { SOS_REPORT_FACADE } from './facade/speed-of-service-facade.injection.token';
import { SOSReportFacade } from './facade/speed-of-service-facade.service';
import { SpeedOfServiceComponent } from './view-component/speed-of-service.component';
import { DataTableComponent } from './dumb-components/data-table/data-table.component';
import { DataTableCumulComponent } from './dumb-components/data-table-with-cumul/data-table-cumul.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
	imports: [
		CommonModule,
		MatProgressBarModule,
		SharedMaterialModule,
		ReactiveFormsModule,
		MatCheckboxModule,
		MatIconModule,
		MatDividerModule,
		MatRadioModule,
		MatInputModule,
		MatFormFieldModule,
		MatTooltipModule,
		SecondsToMins,
		FormsModule,
		TranslocoModule,
	],
	declarations: [
		IndicatorComponent,
		DataTableComponent,
		DataTableCumulComponent,
		DaysSelectorComponent,
		SalesChannelSelectorComponent,
		RestaurantPickerComponent,
		DatePickerComponent,
		ExportButtonComponent,
		SpeedOfServiceComponent,
	],
	providers: [
		{
			provide: SOS_REPORT_FACADE,
			useClass: SOSReportFacade,
		},
	],
	exports: [SpeedOfServiceComponent],
	bootstrap: [],
})
export class SpeedOfServiceModule {}
