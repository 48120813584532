import { Injectable } from '@angular/core';
import * as api from '@dki/api-client';
import { MyRestaurantsSelectors, RootStoreState, TodaysStatisticsActions, TodaysStatisticsSelectors } from '@libs/dash/store';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { SOSReportServiceProvider } from './speed-of-service-facade.provider.interface';

@Injectable()
export class SOSReportFacade implements SOSReportServiceProvider {
	myRestaurants$ = this._store.pipe(select(MyRestaurantsSelectors.getListOfMyRestaurants));
	selectedRestaurants$ = this._store.pipe(select(MyRestaurantsSelectors.getSelectedRestaurants));
	isLoadingSavedRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.getIsLoading));
	isLoading$: Observable<boolean>;

	private _range = { from: DateTime.now().startOf('week'), to: DateTime.now().endOf('week') };
	private _selectedChannel: string | null = null;
	private _selectedDays: string[] = [];
	private _selectedRestaurantIds: string[] = [];

	constructor(private _store: Store<RootStoreState>) {}

	get range(): { from: DateTime<boolean>; to: DateTime<boolean> } {
		return this._range;
	}

	private updateData() {
		this._store.dispatch(
			TodaysStatisticsActions.getKioskSosByDay({
				restaurantIds: this._selectedRestaurantIds,
				from: this._range.from,
				to: this._range.to,
				days: this._selectedDays,
				channel: this._selectedChannel,
			})
		);
	}

	setChannel(channel: string | null) {
		this._selectedChannel = channel;
		this.updateData();
	}

	setRestaurant(restaurants: api.Restaurant[]) {
		this._selectedRestaurantIds = restaurants.map((restaurant) => restaurant.id);
		this.updateData();
	}

	setDays(days: string[]) {
		this._selectedDays = days;
		this.updateData();
	}

	setDateRange(from: DateTime<boolean>, to: DateTime<boolean>) {
		this._range = { from, to };
		this.updateData();
	}

	sosData$ = this._store.pipe(select(TodaysStatisticsSelectors.sosKIOSK));
	sosDataByRestaurant$ = this._store.pipe(select(TodaysStatisticsSelectors.sosKIOSKByRestaurant));
}
